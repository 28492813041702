<!-- 模块说明 -->
<template>
  <div class="container">
    <div class="view">
      <div class="flex">
        <div class="title">账号总览</div>
        <el-button @click="recycleRights" type="primary">
          过期权益回收
        </el-button>
      </div>
      <div class="flex" style="margin-top: 20px">
        <div class="overview">
          <div class="top" style="background: #613bc6">
            {{ minute(creditData.videoTime) || 0 }}
            <span class="bezhu">分钟</span>
          </div>
          <div class="bottom" style="background: #4a25ac">
            短视频制作会员剩余时长
          </div>
        </div>
        <div class="overview">
          <div class="top" style="background: #3591b5">
            {{ minute(creditData.creditTime) || 0 }}
            <span class="bezhu">分钟</span>
          </div>
          <div class="bottom" style="background: #1c7192">
            2.5D无人AI直播会员剩余时长
          </div>
        </div>
        <div class="overview">
          <div class="top" style="background: #495cc2">
            {{ minute(creditData.liveRPTime) || 0 }}
            <span class="bezhu">分钟</span>
          </div>
          <div class="bottom" style="background: #2d40a7">
            3D真人驱动直播会员剩余时长
          </div>
        </div>
      </div>
    </div>
    <div class="view">
      <div class="flex">
        <div class="title">客户详情</div>
        <div class="flex">
          <el-input
            class="waicengPhone"
            v-model="searchEle.phone"
            placeholder="请输入手机号"
            clearable
            maxlength="11"
          ></el-input>
          <el-button style="margin-left: 20px" type="primary" @click="getAll()"
            >查询</el-button
          >
          <el-button @click="openPopup(true)" type="primary">
            添加客户时长
          </el-button>
        </div>
      </div>
      <el-table :data="tableData" stripe class="HNMR">
        <el-table-column prop="phone" label="手机号"> </el-table-column>
        <el-table-column prop="videoTime" label="短视频制作会员剩余时长">
          <template slot-scope="scope">
            {{ minute(scope.row.videoTime) }}分钟
          </template>
        </el-table-column>
        <el-table-column prop="creditTime" label="2.5D无人AI直播会员剩余时长">
          <template slot-scope="scope">
            {{ minute(scope.row.creditTime) }}分钟
          </template>
        </el-table-column>
        <el-table-column prop="liveRPTime" label="3D真人驱动直播会员剩余时长">
          <template slot-scope="scope">
            {{ minute(scope.row.liveRPTime) }}分钟
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作">
          <template slot-scope="scope">
            <el-button @click="openPopup(false, scope.row)" type="text"
              >添加时长</el-button
            >
          </template>
        </el-table-column>
        <template slot="empty">
          <div class="nodata">
            <img src="../../images/back/nodata.png" alt="" />
            <div>暂无数据</div>
          </div>
        </template>
      </el-table>
    </div>
    <el-dialog
      class="commonDialog"
      title="添加客户时长"
      :visible.sync="addDuration"
      width="740px"
    >
      <div class="addDuration">
        <div class="phone flex">
          <div><span v-show="addDurationData.type">*</span> 手机号</div>
          <el-input
            :disabled="!addDurationData.type"
            v-model="addDurationData.phone"
            placeholder="请输入手机号"
            clearable
            maxlength="11"
          ></el-input>
        </div>
        <div class="DurationList">
          <div
            class="view flex"
            v-for="(item, index) in addDurationData.list"
            :key="index"
          >
            <el-checkbox v-model="item.checked" @change="radioTo(item.id)">{{
              item.name
            }}</el-checkbox>
            <template v-if="item.checked">
              <div class="flex" style="width: 200px">
                <el-input
                  v-model="item.time"
                  placeholder="请输入"
                  clearable
                  type="number"
                  style="width: 80%"
                ></el-input>
                分钟
              </div>
              <div class="flex" style="width: 250px; justify-content: center">
                有效期
                <el-input-number
                  v-model="item.expirationDate"
                  :min="1"
                  :max="18"
                  size="small"
                  style="width: 120px; margin: 0 10px"
                ></el-input-number>
                个月
              </div>
            </template>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDuration = false">取 消</el-button>
        <el-button type="primary" @click="submitTo">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  components: {},
  props: [],
  data() {
    return {
      searchEle: {
        phone: null,
      },
      tableData: [],
      creditData: {},
      addDuration: false,
      addDurationData: {
        phone: null,
        type: true,
        list: [
          {
            name: "短视频制作会员时长",
            checked: false,
            time: null,
            expirationDate: 1,
            id: 1,
            url: "allocated/video",
          },
          {
            name: "2.5D无人AI直播会员时长",
            checked: false,
            time: null,
            expirationDate: 1,
            id: 2,
            url: "allocatedCreditTime",
          },
          {
            name: "3D真人驱动直播会员时长",
            checked: false,
            time: null,
            expirationDate: 1,
            id: 3,
            url: "allocated/liveRP",
          },
        ],
      },
    };
  },
  watch: {},
  created() {
    this.getCredit();
  },
  methods: {
    //弹窗提交
    submitTo() {
      let arr = [];
      arr = this.addDurationData.list.map((item) => {
        return item.checked;
      });
      if (arr.indexOf(true) == -1) {
        this.$message.error("请先勾选");
        return;
      }
      for (const i in this.addDurationData.list) {
        let data = this.addDurationData.list[i];
        if (data.checked) {
          if (data.time && data.expirationDate && this.addDurationData.phone) {
            this.allocation(data);
          } else {
            this.$message.error("请完善信息!");
            return;
          }
        }
      }
    },
    //分配数据接口
    allocation(row) {
      let params = {
        phoneNo: this.addDurationData.phone,
        time: +row.time * 60,
        expirationDate: +row.expirationDate,
      };
      this.http.post(`/open/company/open/${row.url}`, params).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            type: "success",
            message: "添加成功!",
          });
          this.addDuration = false;
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    //打开弹窗
    openPopup(type, row) {
      this.addDurationData.type = type;
      if (!type) {
        this.addDurationData.phone = row.phone;
      }
      this.addDuration = true;
    },
    //处理为单选
    radioTo(id) {
      for (const i in this.addDurationData.list) {
        let data = this.addDurationData.list[i];
        if (data.id == id) {
          data.checked = true;
        } else {
          data.checked = false;
        }
      }
    },
    //权益回收
    recycleRights() {
      this.$confirm(
        "确定要对此账号下的所有过期权益进行回收吗？此操作不可逆，请慎重操作！",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          this.http.post("/open/company/recycleRights").then((res) => {
            if (res.data.code == 200) {
              this.$message({
                type: "success",
                message: "回收成功!",
              });
            } else {
              this.$message.error(res.data.message);
            }
          });
        })
        .catch(() => {});
    },
    //获取列表数据
    getAll() {
      if (!this.searchEle.phone) {
        this.$message.error("请输入手机号");
        return;
      }
      this.tableData = [];
      this.http
        .get("/open/company/all/remain/query/" + this.searchEle.phone)
        .then((res) => {
          console.log(res.data);
          if (res.data.code == 200) {
            const obj = res.data.data;
            obj.phone = this.searchEle.phone;
            this.tableData.push(obj);
          } else {
            this.$message.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //获取总数据
    getCredit() {
      this.http
        .get("/open/company/credit")
        .then((res) => {
          console.log(res.data.data);
          this.creditData = res.data.data;
        })
        .catch((err) => console.log(err));
    },
    //秒转分处理
    minute(value) {
      return +value / 60;
    },
  },
  computed: {},
};
</script>
<style lang="less" scoped>
.addDuration {
  .phone {
    .el-input {
      width: 80%;
    }
    span {
      color: #ea6649;
    }
    font-size: 16px;
  }
  padding: 20px;
}
/deep/.HNMR {
  &.noHead {
    &:before {
      height: 0;
    }
    thead {
      display: none;
    }
  }

  thead {
    th {
      height: 56px;
      padding: 0;
      background: #5e73f2 !important;
      font-weight: 800;
      font-size: 16px;
      font-family: PingFang SC;
      color: #2c2e33;
      border-bottom: 1px solid #cccccc;
    }
  }
  .nodata {
    img {
      width: 100px;
      height: 100px;
      margin: 20px auto 0;
    }
    text-align: center;
    margin-bottom: 20px;
  }
  margin-top: 30px;
}
.waicengPhone {
  /deep/.el-input__inner {
    background-color: #030b34;
    border: 1px solid #374597;
    color: white;
  }
}

.container {
  .overview {
    .top {
      .use {
        color: #ea6649;
      }
      .bezhu {
        font-size: 18px;
        font-weight: 400;
      }
      font-weight: bold;
      height: 110px;
      line-height: 110px;
      text-align: center;
      font-size: 32px;
      color: white;
    }
    .bottom {
      font-size: 18px;
      color: white;
      height: 52px;
      text-align: center;
      line-height: 52px;
    }
    width: 400px;
  }
  .flex {
    display: flex;
    justify-content: space-between;
  }
  .view {
    .title {
      color: white;
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 20px;
    }
    margin-top: 40px;
  }
  width: 1400px;
  margin: auto;
}
</style>
